<template>
  <div class="p-grid">
    <div style="margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff"
         :style="expandSidebarDialog ? 'width:70rem;' : 'width:20rem;'"
    >
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0" style="line-height: .9">
          <Button
              v-tooltip="expandSidebarDialog ? _t('label_List_view') : _t('label_Table_view')"
              class="p-button-rounded p-button-icon-only p-button-text"
              :icon="expandSidebarDialog ? 'mdi mdi-table-arrow-left' : 'mdi mdi-table-arrow-right'"
              style="float: right;"
              @click="expandSidebarDialog = !expandSidebarDialog"
          />
          <Button
              v-tooltip.bottom="_t('label_create')"
              class="p-button-rounded p-button-success p-button-text"
              icon="pi pi-plus"
              style="float: right;"
              @click="initNewCalendarDialog()"
          />
          <strong>{{ _t('menu.team_media_schedules') }}</strong>
          <span style="margin-left: 5px; font-size: 12px;">({{ _t('label_prev_Cal_for_participant') }})</span>
        </h3>

        <br>
        <MultiSelectableBreadcrumb
            ref="breadcrumbData"
            :tree-params="treeParams"
            single-selection
        />
      </div>
      <div>
        <span :style="expandSidebarDialog ? 'width:' + ' calc(100% - 3rem)' : 'width:' + ' calc(100% - 3rem);'"
              class="p-input-icon-right p-m-3">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-model="filterValue" style="width:100%;" type="text"/>
        </span>
      </div>
      <DataTable
          ref="tree"
          v-model:selection="selectedCalendarItem"
          :dataKey="tableDataKey"
          :lazy="true"
          :value="getCalendars"
          :page="currentPage"
          :totalRecords="getCalendarMeta.count"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          :paginator="true"
          :rows="tableRows"
          :rowHover="true"
          :scrollable="true"
          stripedRows
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          @page="selectTablePage($event)"
          @row-click="changeCalendar($event.data.id)"
      >
        <Column field="name"
                :header="_t('Name')"
                :style="'width: ' + (100 / teamMediaSchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
        >
          <template #body="{data}">
              <span
                  class="wrap"
                  style="word-break: break-all; padding-left: 5px;"
              >
              {{ data.surname }} {{ data.name }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="description"
                :hidden="!handleCheckRow('description')"
                :header="_t('label_description')"
                :style="'width: ' + (100 / teamMediaSchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
        >
          <template #body="{data}">
              <span
                  style="word-break: break-all; padding-left: 5px;"
              >
                {{ data.description }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                :header="_t('label_org_name')"
                :hidden="!handleCheckRow('orgName')"
                :style="'width: ' + (100 / teamMediaSchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
                field="orgName">
          <template #body="{data}">
              <span
                  style="word-break: break-all; padding-left: 5px;"
              >
                {{ data.orgName }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog"
                field="validFrom"
                :hidden="!handleCheckRow('validFrom')"
                :header="_t('label_valid_from')"
                :style="'width: ' + (100 / teamMediaSchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
        >
          <template #body="{data}">
              <span
                  style="word-break: break-all; padding-left: 5px;"
              >
                {{ data.validFrom }}
              </span>
          </template>
        </Column>

          <Column v-if="expandSidebarDialog"
                  field="Rotation"
                  :header="_t('label_rotation')"
                  :hidden="!handleCheckRow('rotation')"
                  :style="'width: ' + (100 / teamMediaSchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
          >
              <template #body="{data}">
              <span
                      style="word-break: break-all; padding-left: 5px;"
              >
                {{ dateTime(data.validFrom) }}
              </span>
              </template>
          </Column>

          <Column v-if="expandSidebarDialog"
                  field="assignment"
                  :header="_t('label_assignment')"
                  :hidden="!handleCheckRow('assignment')"
                  :style="'width: ' + (100 / teamMediaSchedulesValues.length) + '%; word-break: break-all; min-height: 38px;'"
          >
              <template #body="{data}">
                  <div style="display: flex; flex-direction: column; align-items: flex-start;">
                      <span v-for="(member, idx) in data.membership" :key="member.id"
                           style="word-break: break-all; padding-left: 5px;"
                  >
                {{ member.name }}{{idx !== data?.membership?.length - 1 ? ';' : ''}}
              </span>
                  </div>
              </template>
          </Column>
      </DataTable>
    </div>
    <transition name="slide-fade">
      <div class="p-col">
        <Card v-if="getCalendar" class="p-mt-1 p-ml-0">
          <template #content>
            <div v-if="getCalendar">
              <div class="p-d-flex p-jc-between">
                <div><h3 class="p-m-0"><strong>{{ getCalendar.name }}</strong></h3></div>
                <div>
                  <slot name="controls">
                    <Button v-if="getIsEditable" v-tooltip.bottom="_t(getCalendar.id ? 'Save' : 'Create')"
                            class="p-mr-2 p-button-success p-button-rounded p-button-text"
                            icon="pi pi-save"
                            @click="createUpdateNewCalendar"
                    />
                    <Button v-if="!getIsEditable" v-tooltip.bottom="_t('Edit')"
                            class="p-mr-2 p-button-success p-button-rounded p-button-text"
                            icon="pi pi-pencil"
                            @click="setIsEditable(true)"/>
                    <Button v-if="getCalendar.id" v-tooltip.bottom="'Clone'"
                            class="p-mr-2 p-button-success p-button-rounded p-button-text"
                            icon="pi pi-clone"
                            @click="cloneCalendar"/>
                    <Button v-tooltip.bottom="_t('Delete')"
                            class="p-button-danger p-button-rounded p-button-text"
                            icon="pi pi-trash"
                            @click="removeSelectedCalendar"/>
                    <Button v-if="getIsEditable" v-tooltip.bottom="_t('Cancel')"
                            class="p-button-danger p-button-rounded p-button-text"
                            icon="pi pi-times" @click="cancelCalendar"/>
                  </slot>
                </div>
              </div>
              <TabView :disabled="!selectedCalendarItem && !getCalendar">
                <TabPanel
                    :disabled="!getCalendar"
                    :header="_t('menu.general')"
                >
                  <CalendarConfigurator
                      v-if="getCalendar"
                      ref="configurator"
                      :orgId="orgId"
                      @clone="cloneCalendar"
                  />
                </TabPanel>
                <TabPanel
                    :disabled="!selectedCalendarItem && !getCalendar"
                    :header="_t('PARTICIPANTS')"
                >
                  <div v-if="getAssignment && getAssignment.length">
                    <div>
                      {{_t('label_TMS_used_by_participants')}}
                    </div>
                    <br>
                    <ul>
                      <li
                          v-for="assignment in getAssignment"
                          :key="assignment.id"
                      >
                        {{ assignment.surname }} {{ assignment.name }} <i
                          v-if="assignment.login">({{ assignment.login }})</i>
                      </li>
                    </ul>
                  </div>
                  <div v-else>{{ _t('label_text_isEmpty') }}</div>
                </TabPanel>
              </TabView>
            </div>
          </template>
        </Card>
      </div>
    </transition>
  </div>
</template>

<script>
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb';
import CalendarConfigurator from './calendar-configurator';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {getOrganizationColumns,getUserColumns} from "@/api/uiColumns";
import moment from "moment";

export default {
  components: {MultiSelectableBreadcrumb, CalendarConfigurator},
  data: () => ({
    treeParams: null,
    selectedCalendarItem: null,
    orgId: localStorage.getItem('orgId') ?? 1,
    checkOrgIdIntervalId: null,
    tableRows: 100,
    currentPage: 0,
    filterValue: '',
    expandSidebarDialog: false,
    teamMediaSchedulesValues: [],
  }),
  mounted() {
    this.callGetDevices();
    this.setOrgId(this.orgId)
    this.checkOrgId()

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'teamMediaSchedulesItems') this.teamMediaSchedulesValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key === 'teamMediaSchedulesItems') this.teamMediaSchedulesValues = value
            }
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
  },
  beforeUnmount() {
    clearInterval(parseInt(this.checkOrgIdIntervalId))
  },
  methods: {
    ...mapActions({
      'callCalendars': 'participantCalendar/callCalendars',
      'callCalendarById': 'participantCalendar/callCalendarById',
      'callParticipants': 'participantCalendar/callParticipants',
      'callEvents': 'participantCalendar/callEvents',
      'callGetDevices': 'devices/callGetDevices',
      'callRemoveCalendar': 'participantCalendar/callRemoveCalendar',
      'callCloneCalendar': 'participantCalendar/callCloneCalendar',
      'callAssignment': 'participantCalendar/callAssignment',
    }),
    ...mapMutations({
      'selectCalendar': 'participantCalendar/selectCalendar',
      'createEmptyCalendar': 'participantCalendar/createEmptyCalendar',
      'setCalendar': 'participantCalendar/setCalendar',
      'setIsEditable': 'participantCalendar/setIsEditable',
      'setEvents': 'participantCalendar/setEvents',
    }),
      dateTime(value) {
          return moment(value).format('dddd');
      },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
        }
      }, 500);
    },
    setOrgId() {
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      });
      this.callParticipants(this.orgId);
      if (this.getCalendar && this.getCalendar.id === null) {
        this.setCalendar({
          ...this.getCalendar,
          orgId: this.orgId,
          orgName: this.$refs.breadcrumbData?.breadcrumbs?.[0]?.data?.[0]?.label,
        });
      } else if (this.getCalendar && this.getCalendar.id !== null) {
        this.selectCalendar(null);
        this.selectedCalendarItem = null;
      }
    },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      });
    },
    changeCalendar(id) {
      this.selectCalendar(id ?? null);
      if (id) {
        this.callAssignment(id);
        this.callEvents(id);
      }
    },
    initNewCalendarDialog() {
      const orgName = this.$refs.breadcrumbData?.breadcrumbs?.[0]?.data?.[0]?.label;
      this.createEmptyCalendar({orgId: this.orgId, orgName});
      this.selectedCalendarItem = null;
      this.setIsEditable(true);
    },
    cloneCalendar() {
      this.callCloneCalendar();
      this.setIsEditable(true)
      this.callGetDevices();
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      })
    },
    createUpdateNewCalendar() {
      this.$refs.configurator.createUpdateNewCalendar();
      this.callGetDevices();
    },
    removeSelectedCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveCalendar()
              .then(
                  () => {
                  },
                  error => {
                    console.log(error);
                    this.$root.showMessage(error.reason ?? error, 'error')
                    return false;
                  }
              );
          this.$refs.configurator.validateWeeks();
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    cancelCalendar() {
      if (!this.getCalendar.id) {
        this.callRemoveCalendar()
      }
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      });
      this.callCalendarById(this.getCalendar.id)
      this.setIsEditable(false)
    },
    handleCheckRow(name) {
      const foundedValue = this.teamMediaSchedulesValues.find(({value}) => value === name)
      return !!foundedValue?.checked?.length;
    },
  },
  computed: {
    ...mapGetters({
      'getCalendars': 'participantCalendar/getCalendars',
      'getCalendarMeta': 'participantCalendar/getCalendarMeta',
      'getCalendar': 'participantCalendar/getCalendar',
      'getEvents': 'participantCalendar/getEvents',
      'getIsEditable': 'participantCalendar/getIsEditable',
      'getAssignment': 'participantCalendar/getAssignment',
    }),
  },
  watch: {
    // getCalendar: function() {
    //   this.selectedCalendarItem = this.getCalendar;
    // }
    orgId() {
      this.setOrgId()
    },
    getIsEditable() {
      let that = this
      setTimeout(function () {
        that.callCalendars({
          orgId: that.orgId,
          filterValue: that.filterValue,
          currentPage: that.currentPage,
          tableRows: that.tableRows
        });
      }, 1000, this)

    },
    filterValue() {
      this.callCalendars({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        tableRows: this.tableRows
      });
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
